import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/templates/default-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contact`}</h1>
    <p>{`For business needs, media partners, or reporting of copyright content and user privacy policies, feel free to contact `}<a parentName="p" {...{
        "href": "mailto:fandomnesia1@gmail.com"
      }}>{`fandomnesia1@gmail.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      